import { ProgressBar } from 'react-bootstrap';
import React from 'react';

const PontszamProgressBar = ({ pontszam, szint }) => {

    const ponthatarok = {
    1: 20, //# 30 . 20
    2: 50, //#50 . 30
    3: 100, //# 80 . 40
    4: 180, //# 120 . 50
    5: 300, //# 170 . 60
    6: 470, //# 230 . 70
    7: 700, //# 320 . 80
    8: 1000, //# 400
    9: 1400, //# 500
    10: 1900, //# 600
    11: 2500, //# 700
    12: 3200, //# 800
    13: 4000, //# 1000
    14: 5000, //# 1000
    15: 6000,
    16: 7000,
    17: 8000,
    18: 9000,
    19: 10000,
    20: 12000,
    21: 14000,
    22: 16000,
    23: 18000,
    24: 20000,
    25: 22000,
    26: 24000,
    27: 26000,
    28: 28000,
    29: 30000,
    21: 1280000001,
    }

    const kovetkezoSzintPontszam = ponthatarok[szint];

    // Számítjuk ki, hány pont hiányzik a következő szinthez
    const pontHiany = kovetkezoSzintPontszam - pontszam;

    return (
        <div>
            <h3>Megszerzett pontjaid</h3>
            <ProgressBar
                now={(pontszam / kovetkezoSzintPontszam) * 100}
                label={`${pontszam} pont`}
                style={{
                    height: '40px',
                    fontSize: '20px',
                }}
            />
            <div style={{ marginTop: '10px' }}>
                Hátralévő pont a következő szintig: {pontHiany}
            </div>
        </div>
    );
};

export default PontszamProgressBar;
