import React, { useState } from 'react';
import './SzemelyKepek.css';

function SzemelyKepek({ kepek }) {
  const [fullscreenImage, setFullscreenImage] = useState(null);

  const handleImageClick = (kepUrl) => {
    setFullscreenImage(fullscreenImage === kepUrl ? null : kepUrl);
  };

  return (
    <div className="szemely-kepek mt-4">
      <h4>Képek</h4>
      <div className="image-grid">
        {kepek.map((kepUrl, index) => (
          <img
            key={index}
            src={kepUrl}
            alt={`Személy kép ${index + 1}`}
            onClick={() => handleImageClick(kepUrl)}
            className={fullscreenImage === kepUrl ? "fullscreen" : ""}
          />
        ))}
      </div>
      {fullscreenImage && (
        <div
          className="fullscreen-overlay"
          onClick={() => setFullscreenImage(null)}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.9)",
            zIndex: 999,
            cursor: "pointer",
          }}
        />
      )}
    </div>
  );
}

export default SzemelyKepek;
