import React, { useRef } from 'react';
import RatingScale from './RatingScale';
import { usePost } from "../auth/apicalls";
import { useQueryClient } from "react-query";
import './AOSzempontok.css';  // CSS fájl importálása

function AOSzempontok({ groupedData, szemelyId }) {
  const queryClient = useQueryClient();
  const scrollContainerRef = useRef(null); // Ref a görgethető konténerhez

  const { mutate: postRating } = usePost(`/api/szemely/${szemelyId}/?request_type=add-aoszempont-ertekeles`, {
    onSuccess: () => {
      queryClient.invalidateQueries(`/api/aoszempont/?szemely=${szemelyId}`); // Invalidate query for fresh data
    },
    onError: (error) => {
      console.error("Error submitting rating:", error.response?.data?.user_error || error.message);
    },
  });

  const handleRatingSelect = (aoszempont, ertekeles, status = "helyesnek_tartja") => {
    postRating({ aoszempont, status, ertekeles });

    // Gördítsük le a következő RatingScale elemre
    if (scrollContainerRef.current) {
      const nextRatingScale = scrollContainerRef.current.querySelector('.aoszempont-item:not(.rated)');
      if (nextRatingScale) {
        nextRatingScale.scrollIntoView({ behavior: 'smooth', block: 'start' });
        nextRatingScale.classList.add('rated'); // Jelölje meg, hogy már értékelve lett
      }
    }
  };

  return (
    <div className="grouped-aoszempont mt-4 text-center" ref={scrollContainerRef}>
      {Object.keys(groupedData).map((arcResz) => (
        <div key={arcResz} className="aoszempont-group mb-3">
          <h4>{arcResz}</h4>
          {groupedData[arcResz].map((aoszempont, index) => (
            <div key={index} className="aoszempont-item aoszempont-item-${aoszempont.id}">
              <RatingScale
                aoszempont={aoszempont}
                onRatingSelect={(rating) => handleRatingSelect(aoszempont.id, rating)}
              />
              <button
                className="btn btn-secondary ml-2"
                onClick={() => handleRatingSelect(aoszempont.id, null, "nem_tudja")}
                disabled={aoszempont.ertekeles_id !== null}
              >
                Nem tudom
              </button>
            </div>
          ))}
          <hr />
        </div>
      ))}
    </div>
  );
}

export default AOSzempontok;
