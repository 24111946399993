import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

function SzemelyReszletek({szemelyData}) {

  return (
<div className="szemely-info">
<h2>{szemelyData.full_name}</h2>
<div className="info-grid">
  <div>
    <strong>Feltöltő:</strong>
    <span>@{szemelyData.feltolto}</span>
  </div>
  <div>
    <strong>Tulajdonos:</strong>
    <span>@{szemelyData.owner}</span>
  </div>
  {/* <div>
    <strong>Publikus:</strong>
    <span>
      {szemelyData.global_visibility ? (
        <FaCheckCircle className="icon-positive" />
      ) : (
        <FaTimesCircle className="icon-negative" />
      )}
    </span>
  </div> */}
</div>
</div>
  );
}

export default SzemelyReszletek;