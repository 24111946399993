import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useFetching } from "../auth/apicalls";
import { Loading } from "../alert/Loading";
import { ErrorAlert } from "../alert/ErrorAlert";
import AOSzempontok from './AOSzempontok';
import UploadButton from './UploadPictureButton';
import SzemelyKepek from './SzemelyKepek';
import SzemelyReszletek from './SzemelyReszletek';
import AuthContext from "../userkezeles/AuthContext";

function SzemelyDetail() {
  const { id: szemelyId } = useParams();
  const { isAdmin } = useContext(AuthContext);

  const { data: szemelyData, isError, isLoading } = useFetching(`/api/szemely/${szemelyId}/`);
  const { data: groupedAOSzempontData,
    isError: isAOSzempontError,
    isLoading: isAOSzempontLoading } = useFetching(`/api/aoszempont/?szemely=${szemelyId}`);

  return (
    <> {isAdmin ?
    <div className="szemely-detail mt-4">
      {isLoading && <Loading size={60} />}
      {isError && <ErrorAlert show={isError} text="Could not load szemely details" />}
      {szemelyData && (
        <div className="szemely-card">
          <div className="row">
            <div className="col-6">
            <SzemelyReszletek {...{szemelyData}}/>
            </div>
            <div className="col-3">
              <UploadButton szemelyId={szemelyId} />
            </div>
          </div>
          <SzemelyKepek kepek={szemelyData.szemely_kepek} />
        </div>
      )}
      <div className="aoszempont-section">
        <h3>AO Szempontok</h3>
        {isAOSzempontLoading && <Loading size={40} />}
        {isAOSzempontError && <ErrorAlert show={isAOSzempontError} text="Could not load AO szempontok" />}
        {groupedAOSzempontData && <AOSzempontok groupedData={groupedAOSzempontData} szemelyId={szemelyId} />}
      </div>
    </div>
    : <>Nincs jogosultságod ehhez!</>
    }</>
  );
}

export default SzemelyDetail;
