// SzemelyList.jsx
import React, { useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function SzemelyList({ szemelyek }) {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const navigate = useNavigate();

  const toggleDetails = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const navigateToDetails = (szemelyId) => {
    navigate(`/szemely/${szemelyId}`);
  };

  return (
    <>
      {szemelyek && (
        <div className="szemely-list mt-4">
          {szemelyek.map((szemely, index) => (
            <div
              key={szemely.id}
              className="szemely-item mb-3 p-3 border rounded"
              onClick={() => navigateToDetails(szemely.id)}
              style={{ cursor: 'pointer' }}
            >
              <h4>{szemely.full_name}</h4>
              <p><strong>Feltöltő:</strong> {szemely.feltolto}</p>
              <p><strong>Tulajdonos:</strong> {szemely.owner}</p>
              <div className="d-flex justify-content-between align-items-center">
                <Badge
                  pill
                  variant={szemely.global_visibility ? 'success' : 'warning'}
                  className="mr-3"
                >
                  {szemely.global_visibility ? 'Látható' : 'Nem látható'}
                </Badge>
                <Button
                  variant="link"
                  className="text-decoration-none"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleDetails(index);
                  }}
                >
                  {expandedIndex === index ? 'Részletek elrejtése' : 'Részletek megtekintése'}
                </Button>
              </div>
              {expandedIndex === index && (
                <div className="szemely-description mt-2">
                  <p><strong>Közismert:</strong> {szemely.kozismert ? 'Igen' : 'Nem'}</p>
                  <p><strong>Önmaga:</strong> {szemely.onmaga ? 'Igen' : 'Nem'}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default SzemelyList;
