import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { usePost } from '../auth/apicalls';
import { useQueryClient } from "react-query";


const UploadButton = ({ szemelyId }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const queryClient = useQueryClient();

  const { mutate: uploadImage, isLoading } = usePost(
    `/api/szemely/${szemelyId}/?request_type=add-szemelykep`,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`/api/szemely/${szemelyId}/`);
      },
      onError: (error) => {
        console.error("Error uploading image:", error.response?.data?.user_error || error.message);
      },
    },
    "formData"
  );

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('kep', selectedFile);
      uploadImage(formData);
    } else {
      alert('Kérlek, válassz egy fájlt!');
    }
  };

  return (
    <>
      <Button variant="primary" onClick={() => setShowModal(true)}>
        Kép feltöltése
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Kép feltöltése</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Válassz egy képet:</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Mégse
          </Button>
          <Button
            variant="primary"
            onClick={handleUpload}
            disabled={isLoading}
          >
            {isLoading ? 'Feltöltés...' : 'Feltöltés'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadButton;
