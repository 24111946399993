// SzemelyForm.jsx
import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { usePost } from '../auth/apicalls';

function SzemelyForm({ onSuccess, onClose }) {
  const [formValues, setFormValues] = useState({
    full_name: '',
    owner: false,
    global_visibility: 'public',
  });

  const { mutate: postSzemely, isLoading, isError, error } = usePost('/api/szemely/', {
    onSuccess: () => {
      onSuccess();
      onClose();
    },
    onError: (error) => {
      console.error('Hiba a személy létrehozásakor:', error.response?.data?.user_error || error.message);
    },
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postSzemely(formValues);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>Teljes név</Form.Label>
        <Form.Control
          type="text"
          name="full_name"
          value={formValues.full_name}
          onChange={handleChange}
          placeholder="Adja meg a teljes nevet"
          required
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Check
          type="checkbox"
          label="Tulajdonos?"
          name="owner"
          checked={formValues.owner}
          onChange={handleChange}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Láthatóság</Form.Label>
        <Form.Select
          name="global_visibility"
          value={formValues.global_visibility}
          onChange={handleChange}
        >
          <option value="public">Mindenki láthatja</option>
          <option value="anonym">Anonim mód</option>
          <option value="private">Csak tulajdonos</option>
        </Form.Select>
      </Form.Group>

      {isError && <div className="text-danger">Hiba: {error?.response?.data?.user_error || 'Ismeretlen hiba'}</div>}

      <Button variant="primary" type="submit" disabled={isLoading}>
        {isLoading ? 'Feltöltés...' : 'Létrehozás'}
      </Button>
    </Form>
  );
}

export default SzemelyForm;
