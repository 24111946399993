import React, { useState } from 'react';
import './RatingScale.css';

function RatingScale({ aoszempont, onRatingSelect }) {
  const {
    nev,
    ertekeles,
    ertekeles_id,
    ertekeles_tipus,
    vegpont_1_tooltip,
    vegpont_2_tooltip
  } = aoszempont;

  const isTwoWayScale = ertekeles_tipus === '2 irányú skála';
  const circleCount = isTwoWayScale ? 7 : 4; // 2 irányú: 3 bal, 1 közép, 3 jobb; 1 irányú: 1 bal, 3 jobb
  const middleIndex = isTwoWayScale ? 3 : 0; // 2 irányú: középindex, 1 irányú: szürke kezdőkör

  const [hoverIndex, setHoverIndex] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(
    ertekeles !== null
      ? (isTwoWayScale ? ertekeles + 3 : ertekeles)
      : null
  );

  const handleHover = (index) => {
    setHoverIndex(index);
  };

  const handleSelect = (index) => {
    const rating = isTwoWayScale ? index - 3 : index; // Skála korrekció
    setSelectedIndex(index);
    onRatingSelect(rating);
  };

  const renderCircles = () => {
    return [...Array(circleCount)].map((_, index) => {
      const size = 20 + Math.abs(index - middleIndex) * 10;
      const color = isTwoWayScale
        ? index < middleIndex
          ? `rgba(255, 0, 0, ${0.3 + Math.abs(index - middleIndex) * 0.2})`
          : index > middleIndex
          ? `rgba(0, 0, 255, ${0.3 + Math.abs(index - middleIndex) * 0.2})`
          : '#ccc'
        : index === 0
        ? '#ccc' // Szürke kezdőkör az 1 irányú skálán
        : `rgba(0, 0, 255, ${0.3 + index * 0.2})`;

      const isDisabled =
        ertekeles !== null &&
        (isTwoWayScale
          ? index === ertekeles + 3
          : index === ertekeles);

      return (
        <button
          key={index}
          className={`scale-circle ${
            hoverIndex === index || selectedIndex === index ? 'active' : ''
          } ${ertekeles !== null ? 'faded' : ''} ${
            isDisabled ? 'disabled' : ''
          }`}
          style={{
            width: `${size}px`,
            height: `${size}px`,
            backgroundColor: color,
            position: 'relative',
          }}
          onMouseEnter={() => handleHover(index)}
          onMouseLeave={() => setHoverIndex(null)}
          onClick={() => handleSelect(index)}
          disabled={isDisabled}
        >
          {isDisabled && (
            <span
              className="icon"
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: `${size * 0.4}px`,
                color: '#fff',
              }}
            >
              ✓
            </span>
          )}
        </button>
      );
    });
  };

  return (
    <div className={`rating-scale ${ertekeles !== null ? 'faded' : ''}`}>
      <p className="question">{nev}</p>
      <div className="scale-container">{renderCircles()}</div>
      <div className="labels">
        <span className="label left">
          {vegpont_1_tooltip || ''}
        </span>
        <span className="label center text-secondary">
          {isTwoWayScale
            ? 'kiegyensúlyozott, a két véglet közötti'
            : 'Nincs jele'}
        </span>
        <span className="label right">
          {vegpont_2_tooltip || ''}
        </span>
      </div>
    </div>
  );
}

export default RatingScale;
